import React from 'react'
import ClientList from "../../components/clientList/clientList"
// import Boxes from '../boxes/boxes'
// import RecentBlogs from '../recentBlogs/recentBlogs'

import "./hero.scss"

const Hero = (props) => {

  //const posts = props.data.allNodeBlog.edges;

  return (
    <div className="hero">
        {props.children ? props.children :
          <div className="hero__inner">
            <div className="hero__motd">
              <p>Blue Nebula designs and develops high-fidelity web applications for enterprises worldwide.</p>
              {/* <ClientList /> */}
            </div>
          </div>
        }
      {/* <RecentBlogs posts={posts} /> */}
    </div>
  )
}

export default Hero
