import React, { Component } from 'react'
import Wrapper from '../wrapper/wrapper'
import Img from 'gatsby-image'

export default class ServiceBox extends Component {

  render() {
    const label = this.props.type.slice(0,1).toUpperCase() + this.props.type.slice(1, this.props.type.length);
    return (
      <div id={`sb-${this.props.type}`} className={`serviceBoxes__box serviceBoxes__box--${this.props.type} serviceBoxes__box--${this.props.reverse ? 'reverse' : 'default'}`}>
        <Wrapper>
          <div className="serviceBoxes__box__inner">
            <div className="serviceBoxes__box__text">
              <h1>{label}</h1>
              {this.props.children}
            </div>
            <div className="serviceBoxes__box__image">
              <Img fluid={this.props.img} alt="Design Image" />
            </div>
          </div>
        </Wrapper>
      </div>
    )
  }
}
