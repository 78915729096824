import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import ServiceBox from './serviceBox'

import "./serviceBoxes.scss";

class ServiceBoxes extends React.Component {
  render() {
    return (
      <div className="serviceBoxes">
        <ServiceBox type="design" img={this.props.data.DesignLg.childImageSharp.fluid}>
          <p>First impressions count, and it only takes 10 seconds for a website visitor to make a decision about your product. We specialize in designing simple, clean, and functional websites and apps. Let us help you make a great first impression.</p>
          <ul>
            <li>user experience, design, information architecture</li>
            <li>wireframing, mockups, prototypes</li>
            <li>user interface design, application branding</li>
            <li>design strategy, digital style guides</li>
          </ul>
        </ServiceBox>

        <ServiceBox type="develop" img={this.props.data.DevelopLg.childImageSharp.fluid} reverse>
          <p>We are passionate about writing clean and well-documented code that scales, is easy to maintain, and follows industry-standard best practices.</p>
          <ul>
            <li>open source web technologies</li>
            <li>LAMP, Node.js, API's, systems administration</li>
            <li>JavaScript, front-end frameworks, JAMstack</li>
            <li>command line tools, process automation, custom dashboards</li>
            <li>component driven development</li>
          </ul>
        </ServiceBox>

        <ServiceBox type="support" img={this.props.data.SupportLg.childImageSharp.fluid}>
          <p>Websites and apps require regular maintenance. We ensure your product stays up to date with security releases and bug fixes, while gradually improving and evolving its features. We also provide support for your product, and we can teach you how to maintain it, too!</p>
          <ul>
            <li>training and mentoring</li>
            <li>bugfixing, security updates, patches</li>
            <li>performance optimization</li>
            <li>speaking engagements</li>
          </ul>
        </ServiceBox>

        <ServiceBox type="drupal" img={this.props.data.DrupalLg.childImageSharp.fluid} reverse>
          <p>We have extensive Drupal experience. We can help you at any stage of your Drupal project, whether you need a migration, custom module, custom theme, integration, or more.</p>
          <ul>
            <li>migrations</li>
            <li>custom module development</li>
            <li>custom theme development</li>
            <li>automation & DevOps</li>
          </ul>
        </ServiceBox>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        DesignLg: file(relativePath: {eq: "DesignLg.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        DevelopLg: file(relativePath: {eq: "DevelopLg.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        SupportLg: file(relativePath: {eq: "SupportLg.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        DrupalLg: file(relativePath: {eq: "DrupalLg.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ServiceBoxes data={data} {...props} />}
  />
)
