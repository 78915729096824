import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero";
import ServiceBoxes from "../components/serviceBoxes/serviceBoxes"
import ClientList from "../components/clientList/clientList"
import Cta from "../components/cta/cta"

const ContactPage = () => (
  <Layout flyoutOpen>
    <SEO title="Contact" />
    <Hero />
    <ServiceBoxes />
    <ClientList />
    <Cta />
  </Layout>
)

export default ContactPage
