import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import './clientList.scss'

const ClientList = ({ data }) => {
  return (
    <div className="clientList">
      <h2>Our Clients</h2>
      <div className="clientList__inner">
          {data.allFile.edges.map(({ node }) => (
            <div key={node.id} className="clientList__logo">
              <Img fixed={node.childImageSharp.fixed} alt="" />
            </div>
          ))}
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "logos"}}) {
        edges {
          node {
            id
            relativePath
            base
            childImageSharp {
              fixed(width: 160) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `}
    render={data => <ClientList data={data} {...props} />}
  />
)
